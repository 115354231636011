<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="createRow">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              row-key="id"
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                fixed
                :label="$t('edpBackendMenu.menuName')"
                width="230"
              >
                <template slot-scope="scope">
                  {{
                    $i18n.locale === "cn"
                      ? scope.row.menuCnName
                      : scope.row.menuEnName
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="menuCode"
                :label="$t('edpBackendMenu.menuCode')"
              >
              </el-table-column>

              <el-table-column :label="$t('edpBackendMenu.url')">
                <template slot-scope="scope">
                  {{ scope.row.menuLinkUrl || "--" }}
                </template>
              </el-table-column>

              <el-table-column
                prop="menuSorted"
                :label="$t('edpBackendMenu.sort')"
              >
              </el-table-column>

              <el-table-column
                :label="$t('edpBackendMenu.control')"
                fixed="right"
                width="150"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="setRow(scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      class="edp-backend-dialogEl"
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="130px">
        <el-form-item :label="$t('edpBackendMenu.menuCode')" prop="menuCode">
          <el-input
            v-model="form.menuCode"
            clearable
            :disabled="dialogType === 'update'"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendMenu.menuCnName')"
          prop="menuCnName"
        >
          <el-input v-model="form.menuCnName" clearable></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendMenu.menuEnName')"
          prop="menuEnName"
        >
          <el-input v-model="form.menuEnName" clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendMenu.selectParent')">
          <el-cascader
            v-model="form.menuParentIdArray"
            :placeholder="$t('edpBackendCommon.select')"
            :options="menuOptions"
            :props="{ checkStrictly: true }"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item :label="$t('edpBackendMenu.sort')">
          <el-input v-model="form.menuSorted" clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendMenu.url')">
          <el-input v-model="form.menuLinkUrl" clearable></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="dialogVisible_submit">{{
          $t("edpBackendCommon.define")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "backendMenu",
  data() {
    return {
      pageList: [],
      currentPage: 1,
      pageSize: 1000,
      loading: true,

      dialogTitle: "",
      dialogVisible: false,
      dialogType: "",

      form: {
        id: "",
        menuCode: "",
        menuCnName: "",
        menuEnName: "",
        menuParentId: "",
        menuParentIdArray: [],
        menuSorted: 0,
        menuLinkUrl: "",
      },
      menuOptions: [],

      rules: {
        menuCode: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendMenu.menuCode"),
            trigger: "blur",
          },
        ],
        menuCnName: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendMenu.menuCnName"),
            trigger: "blur",
          },
        ],
        menuEnName: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendMenu.menuEnName"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      menuList: "backendMenu/menuList",
      menuCreate: "backendMenu/menuCreate",
      menuUpdate: "backendMenu/menuUpdate",
      menuDelete: "backendMenu/menuDelete",
    }),

    async pageInit(num) {
      let _this = this;
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      let params = {
        current: this.currentPage,
        size: this.pageSize,
      };

      let res = await this.menuList(params);

      this.pageList = res.data.data;

      this.menuOptions = JSON.parse(JSON.stringify(this.pageList));

      function menuListInit(data) {
        let pageLs = data;
        pageLs.forEach((val, e) => {
          _this.$set(val, "value", val.id);

          if (!val.spIndex) {
            _this.$set(val, "spIndex", [val.id]);
          }

          _this.$i18n.locale === "cn"
            ? _this.$set(val, "label", val.menuCnName)
            : _this.$set(val, "label", val.menuEnName);

          if (val.children && val.children.length > 0) {
            val.children.forEach((valchild) => {
              let spOutLs = val.spIndex;
              valchild.spIndex = spOutLs.concat([valchild.id]);
            });
            menuListInit(val.children);
          }
        });
      }

      menuListInit(this.menuOptions);

      this.loading = false;
    },
    createRow() {
      this.dialogType = "";
      this.dialogTitle = this.$t("edpBackendCommon.add");

      for (var key in this.form) {
        this.form[key] = key === "menuSorted" ? 0 : "";
      }

      this.dialogVisible = true;
    },
    setRow(data) {
      this.dialogType = "update";
      this.dialogTitle = this.$t("edpBackendCommon.edit");
      this.form.menuParentIdArray = "";

      for (var key in this.form) {
        this.form[key] = data[key];
      }

      let _this = this;

      function eachId(objTree) {
        objTree.forEach((val) => {
          if (
            val.spIndex &&
            val.spIndex[val.spIndex.length - 1] == _this.form.menuParentId
          ) {
            _this.form.menuParentIdArray = val.spIndex;
          }

          if (val.children && val.children.length > 0) {
            eachId(val.children);
          }
        });
      }

      eachId(_this.menuOptions);

      this.dialogVisible = true;
    },
    deleteRow(data) {
      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.menuDelete(data.id);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit(1);
        }
      });
    },
    dialogVisible_submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = {};

          let formParams = JSON.parse(JSON.stringify(this.form));

          if (!formParams.menuSorted) {
            formParams.menuSorted = 0;
          }

          if (
            this.form.menuParentIdArray &&
            this.form.menuParentIdArray.length > 0
          ) {
            formParams.menuParentId =
              this.form.menuParentIdArray[
                this.form.menuParentIdArray.length - 1
              ];
          } else {
            formParams.menuParentId = "";
          }

          if (this.dialogType === "update") {
            res = await this.menuUpdate(formParams);
          } else {
            res = await this.menuCreate(formParams);
          }

          if (res.data.success) {
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });

            this.dialogVisible = false;

            this.pageInit(1);
          }
        }
      });
    },
  },
  async mounted() {
    this.pageInit();
  },
};
</script>
